import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import dynamic from 'next/dynamic'

/**
 * Components
 */
const LoadingCard = dynamic(() => import('../../cards/listings/LoadingCard'))
const UnitCard = dynamic(() => import('../../cards/listings/UnitCard'))

/**
 * Utils
 */
import { IUnitHomepage } from '../Index'
import { generateUrl, translateDirectionLocation, unauthorizedErrorHandler } from '../../../utils/Helper'
import DefaultAxios from '../../../utils/DefaultAxios'
import LegacyCarousel from '../../Carousel/LegacyCarousel'

interface StarListingSectionProps {
    lang: any
    langCode: string
    isLoggedIn: boolean
    setLoginModalOpen: Dispatch<SetStateAction<number>>
    initialData: IUnitHomepage[]
}

const areas = [
    { label: 'Jakarta Selatan', value: 'jakarta-selatan' },
    { label: 'Jakarta Barat', value: 'jakarta-barat' },
    { label: 'Jakarta Timur', value: 'jakarta-timur' },
    { label: 'Jakarta Pusat', value: 'jakarta-pusat' },
    { label: 'Jakarta Utara', value: 'jakarta-utara' },
    { label: 'Tangerang', value: 'tangerang' },
    { label: 'Bekasi', value: 'bekasi' },
]

const StarListingSection = ({ lang, langCode, initialData, isLoggedIn, setLoginModalOpen }: StarListingSectionProps) => {
    const [selectedArea, setSelectedArea] = useState('jakarta-selatan')
    const [clientSideStarListing, setClientSideStarListing] = useState<IUnitHomepage[]>([])
    const [loading, setLoading] = useState(true)
    const [redrawMobileCarousel, setRedrawMobileCarousel] = useState(0)

    const fetchStarListing = (area: string) => {
        setLoading(true)
        DefaultAxios.get(`${process.env.NEXT_PUBLIC_PHP_API_URL}/site/starlisting-homepage?area_slug=${area}`)
            .then(res => res.data)
            .then((data: any) => {
                setClientSideStarListing(data)
            })
            .catch(unauthorizedErrorHandler)
            .finally(() => {
                setLoading(false)
                setRedrawMobileCarousel(prev => prev + 1)
            })
    }

    const handleChangeArea = (area: string) => {
        setSelectedArea(area)
        fetchStarListing(area)
    }

    useEffect(() => {
        fetchStarListing(selectedArea)
    }, [])

    const renderList = useMemo(() => {
        const loadingCard = [1, 2, 3, 4].map(index =>
            <div className="lg:mb-6 w-11/12 lg:w-1/4 px-2 flex-shrink-0" key={`loading-card-${index}`}>
                <LoadingCard containerClasses="p-3 lg:p-0 lg:border border-gray-e6 h-full" contentPadding="lg:px-4 py-2 lg:py-4" />
            </div>
        )
        const defaultRender = clientSideStarListing.map((unit: IUnitHomepage, index: number) =>
            <div key={`${unit.apartment_id}-${selectedArea}-${index}`} className={`bg-white w-11/12 lg:w-1/4 px-2 flex-shrink-0`} style={{ scrollSnapAlign: 'start' }}>
                <UnitCard
                    floor=''
                    id={unit.id as number}
                    photos={unit.photos}
                    is_wishlisted={unit.is_wishlisted}
                    is_star={Number(unit.category) === 1}
                    is_verified={Number(unit.category) === 2}
                    is_dollar={!!unit.is_dollar}
                    is_free_service={unit.service_charge_type === 2}
                    is_special_price={!!unit.is_special_price}
                    is_negotiable={!!unit.is_negotiable}
                    code={unit.code}
                    title={unit.name}
                    apartment_name={unit.apartment_name}
                    bedroom={unit.bedroom}
                    bathroom={unit.bathroom}
                    price={unit.price ? unit.price.toString() : "0"}
                    property_area={unit.unit_area!}
                    tower={unit.tower}
                    url={generateUrl(langCode, `/sewa-apartemen-${unit.apartment_slug!}/${unit.code!}`)}
                    lang={lang}
                    langCode={langCode as ('en' | 'id')}
                    priceDuration="yearly"
                    isLoggedIn={isLoggedIn}
                    containerClasses='w-full px-0'
                    setLoginModalOpen={setLoginModalOpen}
                />
            </div>
        )

        if (loading) return loadingCard
        return defaultRender
    }, [loading, clientSideStarListing, selectedArea])

    return (
        <>
            <h3 className="text-2xl mb-2 lg:mb-4 font-bold">{lang['star_listing_heading']}</h3>
            <span className='lg:hidden block text-sm text-#727272 mb-3'>Top Rated, Fully Furnished Unit with Best Value</span>
            <div className='flex flex-row gap-10px mb-5 overflow-y-scroll no-scrollbar-all' style={{ scrollSnapType: 'x mandatory' }}>
                {
                    areas.map((area) => (
                        <button
                            onClick={() => handleChangeArea(area.value)} key={area.value} className={`flex-0 whitespace-no-wrap outline-none focus:outline-none cursor self-start py-10px px-6 text-sm border rounded-full ${selectedArea === area.value ? 'text-white bg-main border-transparent' : 'text-#727272 bg-white border-gray-dd'} `}
                            style={{ scrollSnapAlign: 'start' }}
                        >
                            {translateDirectionLocation(area.label, langCode)}
                        </button>
                    ))
                }
            </div>
            <div className='hidden lg:block -mx-2'>
                {
                    renderList.length ?
                        <LegacyCarousel
                            key={selectedArea}
                            show={4}
                            sliderClass='sliderclass'
                        >
                            {renderList}
                        </LegacyCarousel>
                        : null
                }
            </div>
            <div key={redrawMobileCarousel} className="flex flex-row -mx-2 overflow-y-scroll no-scrollbar-all lg:hidden" style={{ scrollSnapType: 'x mandatory' }}>
                {renderList}
            </div>
        </>
    )
}

export default StarListingSection
