import React, { useState, ReactNode, useEffect, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';

interface IProps {
    show: number
    children: ReactNode[]
    sliderClass?: string
}

const LegacyCarousel = ({ show, children, sliderClass }: IProps) => {
    const [state, setState] = useState({
        activeIndex: 0
    })
    
    const [length, setLength] = useState(children.length)
    
    useEffect(() => {
        window.addEventListener('resize', debouncedHandleResize)

        return () => {
            window.removeEventListener('resize', debouncedHandleResize)
        }
    }, [])

    const debouncedHandleResize = useCallback(_.debounce(() => {
        setState({activeIndex: 0})
    }, 250), [])

    useEffect(() => {
        setLength(children.length)
    }, [children])

    const nextCard = () => {
        let newActiveIndex = state.activeIndex;
        newActiveIndex = newActiveIndex+1;
        setState({ activeIndex : newActiveIndex });
    }

    const prevCard = () => {
        let newActiveIndex = state.activeIndex;
        newActiveIndex = newActiveIndex-1;
        setState({ activeIndex : newActiveIndex });
    }

    return (
        <div className="relative my-0 mx-auto">
            {
                state.activeIndex > 0 ?
                <button className="hidden lg:block absolute left-0 top-1/2 z-5 transform -translate-y-1/2 -translate-x-1/2 rounded-full px-3 ml-1 py-1 bg-white shadow cursor-pointer text-lg outline-none focus:outline-none active:outline-none" onClick={() => prevCard()}>
                    <FontAwesomeIcon icon="angle-left" size="lg" width="0" className="text-gray-77" style={{ transform: 'translateX(-1px)' }} />
                </button>
                : null
            }
            <div className="overflow-auto lg:overflow-hidden lg:p-0 no-scrollbar-all">
                <div className={`flex transition-all duration-300 ease-linear ${sliderClass || ''}`} style={{ transform : `translateX(-${state.activeIndex * (100/show)}%)`}}>
                    { children }
                </div>
            </div>
            {
                state.activeIndex < length - show ?
                <button className="hidden lg:block absolute right-0 top-1/2 z-5 transform -translate-y-1/2 translate-x-1/2 rounded-full px-3 mr-1 py-1 bg-white shadow cursor-pointer text-lg outline-none focus:outline-none active:outline-none" onClick={() => nextCard()}>
                    <FontAwesomeIcon icon="angle-right" size="lg" width="0" className="text-gray-77" style={{ transform: 'translateX(1px)' }} />
                </button>
                : null
            }
        </div>
    )
}

export default LegacyCarousel
